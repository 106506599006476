<template>
    <div>
        <a-space class="inputContainer" :size="20">
            <a-space class="inputContent" :size="20" direction="vertical">
                <div class="inputLabel">
                    Selfie with passport
                    <a-upload name="file" :showUploadList="false"
                    :action="uploadUrl" :headers="headers" maxCount="1"
                        :before-upload="beforeUpload" @change="handleChange">
                        <a-button class="uploadBtn" type="primary">
                            <span>
                                upload
                                <img src="@/assets/cloudUpload.svg" alt="upload" />
                            </span>
                        </a-button>
                    </a-upload>
                </div>
                <div>
                    <div class="idCardContainer">
                        <a-spin :spinning="frontLoading">
                            <img v-if="frontHttpImgUrl||frontImgUrl"
                            :src="frontHttpImgUrl||frontImgUrl" alt="front card" />
                            <img v-else src="@/assets/passwordHoldImg.png" alt="front card" />
                        </a-spin>
                    </div>
                </div>
            </a-space>
        </a-space>
        <div class="uploadDescribe">
          Make sure the text on the passport is clear (please do not use selfie
            mode or mirror the photo); your entire face is visible, and your
            shoulders and arms are included in the photo.</div>

    </div>
</template>

<script>
import { baseAdminUrl } from '@/utils/baseUrl';

export default {
  name: 'ddd',
  props: ['value', 'http'],
  data() {
    return {
      text: '',
      frontLoading: false,
      frontHttpImgUrl: '',
      frontImgUrl: '',
      // uploadUrl: '',
      // headers: {},
      uploadUrl: `${baseAdminUrl}/v1/common/file/public`,
      headers: {
        authorization: localStorage.getItem('inst_money_access_token') || '',
      },
    };
  },
  methods: {
    submit() {
      this.$emit('input', this.frontImgUrl);
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG/PNG file!');
      }
      const isLt4M = file.size / 1024 / 1024 < 4;
      if (!isLt4M) {
        this.$message.error('Image must smaller than 4MB!');
      }
      if (isJpgOrPng && isLt4M) {
        if (this.http) {
          return true;
        }
        this.getBase64(file).then((res) => {
          console.log(res);
          this.frontImgUrl = res;
          this.$emit('input', res);
        });
        return false;
      }


      return false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    handleChange(info) {
      console.log(info);
      console.log(info.file.status);
      if (info.file.status === 'uploading') {
        this.frontLoading = true;
        return;
      }
      if (info.file.status === 'done') {
        this.frontLoading = false;
        if (info?.file?.response?.code === 0) {
          this.frontHttpImgUrl = info?.file?.response?.result;
          this.$emit('input', info?.file?.response?.result);
        }
      }
    },
  },

};

</script>


<style lang="scss" scoped>
.inputContainer {
    margin-top: 20px;

    @media (max-width: 960px) {
        display: block;

        /deep/ .ant-space-item {
            width: 100%;
        }
    }

    width: 100%;

    /deep/ .ant-space-item {
        flex: 1;
    }

    .inputContent {
        width: 100%;

        .inputLabel {
            font-size: 16px;
        }

        .stepInput {
            height: 60px;
            border-radius: 8px;

            /deep/ input {
                height: 60px;
            }
        }

        .ant-select {
            width: 100%;
            height: 60px;

            /deep/ .ant-select-selection {
                height: 60px !important;
                border-radius: 8px;

                .ant-select-selection__rendered {
                    height: 60px !important;
                    line-height: 60px;
                }
            }
        }

        .ant-calendar-picker {
            height: 60px;
            width: 100%;
            border-radius: 8px;

            /deep/ .ant-calendar-picker-input {
                height: 60px;
            }
        }

        .uploadBtn {
            margin-left: 20px;
            border-radius: 4px;
            font-size: 0;

            span {
                font-size: 14px;
            }

            img {
                height: 24px;
                margin-left: 10px;
            }
        }

        .idCardContainer {
            width: 100%;
            border: 1px solid #d9d9d9;
            border-radius: 8px;
            margin-bottom: 10px;
            text-align: center;
            padding: 20px;

            img {
                max-width: 90%;
                height: 180px;
            }
        }

        .amountTips {
            text-align: end;

            span {
                font-size: 14px;
                opacity: 0.5;
            }
        }

        .inputBottom {
            color: #0045ff;
            font-size: 20px;
        }
    }
}

.uploadDescribe {
    display: block;
    font-size: 14px;
    line-height: 24px;
    opacity: 0.3;
}
</style>
